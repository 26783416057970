import { fetchData, setAuthHeader } from '../utils/datasource';

export const sendSignInRequest = async (username, password) => {
  try {
    // console.log('converting url params...')
    // const params = new URLSearchParams({
    //   grant_type: 'password',
    //   username: username,
    //   password: password
    // });

    // const response = await fetchData(`/api/oauth2/v1/token?${params.toString()}`, 'POST');
    // const response = await fetchData(`/api/oauth2/v1/token?grant_type=password&password=${encodeURIComponent(password)}&username=${encodeURIComponent(username)}`, 'POST');


    // console.log(`password: ${password}`)
    // password = encodeURIComponent(password)
    // console.log(`password: ${password}`)
    
    // console.log(`username: ${username}`)    
    // username = encodeURIComponent(username)    
    // console.log(`username: ${username}`)

    const response = await fetchData(`/api/oauth2/v1/token?grant_type=password&password=${password}&username=${username}`, 'POST');
    const loginData = await response.json()

    // Verifica se há um erro no login (exemplo: PASS_INVALID_TESTE)
    if (!loginData.access_token) {
      throw new Error(`Erro de autenticação: ${loginData.erro_access}`);
    }

    // Codifica usuário e senha em Base64
    const credentials = btoa(`${username}:${password}`);

    // Adiciona os dados no localStorage
    localStorage.setItem('authCredentials', JSON.stringify({
      ...loginData,
      credentials
    }));

    return {
      isOk: true,
      // data: defaultUser
    };
  }
  catch (error) {
    console.error(error)
    return {
      isOk: false,
      message: error.message
    };
  }
}

export const getUser = async () => {
  try {
    const credentials = JSON.parse(getAuthCredentials());

    if (credentials) {
      // Configurar o cabeçalho de autorização com o token
      setAuthHeader(credentials.access_token);

      // Decodificar o token JWT para obter o userId
      function parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map((c) => {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );

        return JSON.parse(jsonPayload);
      }

      const tokenPayload = parseJwt(credentials.access_token);
      const userId = tokenPayload.userid; // Obter o campo userid do payload do token

      // Buscar os dados dos usuários
      const response = await fetchData('/api/framework/v1/users/' + userId, 'GET');
      const user = await response.json();



      return {
        isOk: true,
        data: user,
      };
    } else {
      console.log('No credentials found');
      return {
        isOk: false,
        message: 'No credentials found',
      };
    }
  } catch (error) {
    console.error('Error in getUser():', error);
    return {
      isOk: false,
    };
  }
};


export const checkSecurity = async () => {
  try {
    const credentials = JSON.parse(getAuthCredentials());

    if (credentials) {
      setAuthHeader(credentials.access_token);
      const data = await fetchData('/check_security', 'GET');
      return {
        isOk: true,
        data
      };
    } else {
      console.log('No credentials found')
      return {
        isOk: false,
        message: 'No credentials found'
      };
    }
  }
  catch {
    return {
      isOk: false
    };
  }
}

const getAuthCredentials = () => {
  const credentials = localStorage.getItem('authCredentials');
  if (credentials) {
    return credentials
  }
  return null;
};